import PhoneIcon from "../../../../assets/icons/iconcall.svg";
import WMTSLayer from "@arcgis/core/layers/WMTSLayer";
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import Graphic from "@arcgis/core/Graphic";
import EsriConfig from "@arcgis/core/config";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import { getMapLayerList } from '../../../../services/apiCalls/mapApi';

const iconPath = "M6.729,19.6C1.054,11.368,0,10.524,0,7.5a7.5,7.5,0,1,1,15,0c0,3.024-1.054,3.868-6.729,12.1a.938.938,0,0,1-1.542,0ZM7.5,10.625A3.125,3.125,0,1,0,4.375,7.5,3.125,3.125,0,0,0,7.5,10.625Z";

const convertDateFormat = (dateString) => {
    let date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

const popupTrailheads = {
    "content": `<div class="esri-popupcard">
        <div class="popup-inner"></div>
        <div class="esri-popupcard-title">
          <p>$100K NZD,</p> 
          <span>15 Sep 2022</span>
        </div>
          <span>All walls have been damaged due to eart…</span>
          <p>Malory St., 35 Street, House No. 150A, Co…</p>
      </div>`
}

const card = (location) => {
    return `
        <div class="esri-maplistview popup">
            <div class="esri-maplistview-info">
                <h2>Claim Number: ${location.claimNumber}</h2>
                <h3>Policy Number: ${location.policyNumber}</h3>
                <h3>Loss Date: ${convertDateFormat(location.lossDate)}</h3>
                <h3>Claim Date: ${convertDateFormat(location.claimRaisedDate)}</h3>
                <span>Claim Type: ${location.claimType}</span>
                <span>Status: ${location.claimStatus}</span>
                <p>Address: ${location.address}</p>
                <div class="esri-maplistview-phone">
                    <p class="mapviewer-call-icon">+91 - 9654566719</p>
                </div>
            </div>
        </div>`
}

const baseMaps = [
    {
        category: "Basemap",
        name: "Aerial",
        url: "https://basemaps.linz.govt.nz/v1/tiles/aerial/WebMercatorQuad/WMTSCapabilities.xml?api=c01ghdxf08jr6rqwh49b33h2pbg",
        layerType: "WMTSLayer",
        authenticationType: "None",
        apiKey: "",
        userName: "",
        password: "",
        authUrl: ""
    },
    {
        category: "Basemap",
        name: "Topo-Graphic",
        url: "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer",
        layerType: "VectorTileLayer",
        authenticationType: "None",
        apiKey: "",
        userName: "",
        password: "",
        authUrl: ""
    }
]

const UrlParams = (urlString) => {
    const url = new URL(urlString);
    const params = new URLSearchParams(url.search)
    let parameters = {};
    for (const param of params) {
        const field = param[0];
        parameters = {...parameters, [field]:param[1]};
    }
    return parameters;
}

const BasemapDetails = (visibleLayer) => {
    let layers = []  
    let layerType = [...baseMaps];
    layerType.map(layer => {
        let data;
        switch(layer.layerType){
            case "WMTSLayer" : {
                const params = UrlParams(layer.url)
                data = new WMTSLayer({
                    url: layer.url.split("?")[0],
                    customParameters: params,
                    copyright: layer.copyRightText,
                    activeLayer: {
                      id: "SRTM_Color_Index"
                    },
                    id: layer.name,
                    title: layer.name,
                    visible: visibleLayer === "Aerial" ? true : false,
                })
                break;
            }
            case "VectorTileLayer" : {
                data = new VectorTileLayer({
                    url: layer.url,
                    id: layer.name,
                    visible: visibleLayer === "Topo-Graphic" ? true : false,
                })
                break;
            }
            default : {
                data = null
            }
        }
        layers.push(data)
    })
    return layers
}

const LayerDetails = (layerInfo) => {
    let layers = []  
    const LayerData = JSON.parse(window.localStorage.getItem('layer'));
    let list = layerInfo ? layerInfo : [];
    let layerType = [...list];
    layerType.map(layer => {
        let data;
        switch(layer.layerType){
            case "WMTSLayer" : {
                const params = UrlParams(layer.url)
                data = new WMTSLayer({
                    url: layer.url.split("?")[0],
                    customParameters: params,
                    copyright: layer.copyRightText,
                    activeLayer: {
                      id: "SRTM_Color_Index"
                    },
                    id: layer.name,
                    title: layer.name,
                    visible: layer.name === "Aerial" ? true : false,
                })
                break;
            }
            case "WebTileLayer" : {
                const params = UrlParams(layer.url);
                data = new WebTileLayer({
                    urlTemplate: layer.url.split("?")[0],
                    // customParameters: params,
                    id: layer.name,
                    title: layer.name,
                    visible: LayerData?.includes(layer.name) ? true : false,
                })
            }
            case "FeatureLayer" : {
                if (layer.authToken !== '') {
                    EsriConfig.request.interceptors.push({
                    // set the `urls` property to the URL of the FeatureLayer so that this
                    // interceptor only applies to requests made to the FeatureLayer URL
                    urls: layer.url,
                    // use the BeforeInterceptorCallback to add token to query
                    before: function(params) {
                      params.requestOptions.query = params.requestOptions.query || {};
                      params.requestOptions.query.token = layer.authToken;
                    },
                  });
                }
                data = new FeatureLayer({
                    id: layer.name,
                    url: layer.url,
                    customParameters: {
                        returnIdsOnly : false,
                        returnCountOnly: false,
                    },
                    visible: LayerData?.includes(layer.name) ? true : false,
                    // popupTemplate: popupTrailheads
                }) 
                break;
            }
            case "GraphicsLayer" : {
                data = new GraphicsLayer({
                    id: layer.name,
                    title: layer.name,
                    visible: LayerData?.includes(layer.name) ? true : false,
                })
                break;
            }
            case "VectorTileLayer" : {
                data = new VectorTileLayer({
                    url: layer.url,
                    id: layer.name,
                    visible: LayerData?.includes(layer.name) ? true : false,
                })
                break;
            }
            default : {
                data = null
            }
        }
        layers.push(data)
    })
    const sketchLayer = new GraphicsLayer({
        id: "Sketch"
    });

    layers.push(sketchLayer)

    return layers
}

const ClaimsDataDetails = (claim) => {
    let graphicLayer = []  
    const LayerData = JSON.parse(window.localStorage.getItem('layer'));
    const PointedLocation = new GraphicsLayer({
        id: "Pointed Location",
        title: "Pointed Locations",
        visible: LayerData?.includes("Pointed Location") ? true : false,
    });

    const Symbol = new SimpleMarkerSymbol({
        path: iconPath,
        color: "#2f2f5d",
        size: "24px",
        border: '#2f2f5d'
    });

    claim?.map((location) => {
        const graph = new Graphic({
            geometry: {
                type: "point",
                latitude: location?.locationY,
                longitude: location?.locationX,
            },
            symbol: Symbol,
            attributes: {
                title: location?.claimNumber,
                content: location?.address,
            },
            popupTemplate: {
                content: card(location)
            },
        });
        PointedLocation.add(graph);
    });
    graphicLayer.push(PointedLocation)

    return graphicLayer[0]
}

const LayerList = (layerInfo) => {
    let layerList = [];
    let data = layerInfo;
    let layerType = [...baseMaps,...data]
    layerType.forEach((layer) => {
        layerList.push(layer.name);  
    });
    layerList.push("Pointed Location")
    return layerList
}

const CategoryList = (layerInfo) => {
    let categoryList = [];
    let data = layerInfo;
    let layerType = [...baseMaps,...data]
    layerType.forEach((category) => {
        if(!categoryList.includes(category.category) && category.category !== "Basemap"){
            categoryList.push(category.category);
        }
    });
    return categoryList
}

const LayerListByCategory = (layerInfo) => {
    let categoryByLayer = {"Landcheck": ["Pointed Location"]}
    let data = layerInfo;
    let layerType = [...baseMaps,...data]
    layerType.map((layer) => {
        const category = layer.category;
        if(categoryByLayer[category]){
            categoryByLayer[category] = [...categoryByLayer[category],layer.name]
        }
        else{
            categoryByLayer[category] = [layer.name]
        };
    });
    return categoryByLayer;
}

export {
    LayerList,
    CategoryList,
    LayerListByCategory,
    LayerDetails,
    BasemapDetails,
    ClaimsDataDetails
};